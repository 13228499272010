import BrowserFrame from "../browserFrame";
import Image from "next/image";
import Link from "next/link";
import useFreeTryURL from "../../../utils/useFreeTryURL";
import VideoBrowserFrame from "../videoBrowserFrame";
import { usePlausible } from "next-plausible";

export default function VideoFeature() {
  const plausible = usePlausible();

  const handleClick = (buttonId: any) => {
    try {
      plausible(buttonId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    // CHANGE: change py-24 to pb-24 for video
    <div className="bg-tootler-grey-900 overflow-visible my-0 py-24">
      {/* <div className="relative -top-40 ring-2 md:ring-4 ring-tootler-primary-400 rounded-2xl mx-auto w-[90%] h-[300px] md:h-[70vh] max-h-[700px] max-w-7xl overflow-hidden">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube-nocookie.com/embed/dnxOtRIdE3k"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div> */}
      {/* CHANGE: add -mt-20 to the div below for video */}
      <div
        className="text-white 
      
      items-center text-center flex flex-col"
      >
        {/* CHANGE: Change the text here for video */}
        <h2 className="font-bold text-2xl lg:text-4xl ">Book a short demo</h2>
        <p className="mx-auto mt-6 max-w-xl text-lg md:text-xl leading-8 text-slate-400">
          Go through this demo to understand the product & find your purpose
        </p>
        <Link
          onClick={() => handleClick("landing-btn-book-a-demo")}
          href="https://calendly.com/tootler/30min"
          className="mt-8 rounded-md bg-white px-5 py-2.5 text-base font-semibold leading-7 text-tootler-grey-900 hover:bg-tootler-grey-200 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tootler-primary-400"
        >
          10% off on demo! Try now
        </Link>
      </div>
    </div>
  );
}
