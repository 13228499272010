import TestimonialCard from "./TestimonialCard";
import review from "../../../constant/reveiws.json";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useRef } from "react";
const Testimonial = () => {
  const testimonialContainer = useRef<HTMLDivElement>(null);
  const scroll = (scrollOffset: number) => {
    testimonialContainer.current?.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  return (
    <section className="flex items-center px-4 gap-2 md:gap-4 max-w-7xl mx-auto">
      <ChevronLeftIcon
        onClick={() => scroll(-300)}
        className="h-10 w-10 text-gray-500 cursor-pointer"
      />
      <div
        ref={testimonialContainer}
        className="grid grid-flow-col items-center gap-10 overflow-x-auto py-20 scrollbar-hide w-full"
      >
        {review.map((data: any, index: number) => {
          return (
            <TestimonialCard
              key={index}
              src={data.src}
              name={data.name}
              review={data.review}
              country={data.country}
            />
          );
        })}
      </div>
      <ChevronRightIcon
        onClick={() => scroll(300)}
        className="h-10 w-10 text-gray-500 cursor-pointer"
      />
    </section>
  );
};

export default Testimonial;
