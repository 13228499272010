const features = [
  {
    name: "Plagiarism Checker",
    description:
      "Inbuilt tool that helps you to check your content for plagiarism. It is a very useful tool for students, teachers, and writers.",
    icon: "/landing/features/Icons/plagiarism-checker.svg",
  },
  {
    name: "In-built Editor",
    description:
      "Customize your SOPs on the go with our in-built editor. You can add text formatting like bold, italics, underline, and more.",
    icon: "/landing/features/Icons/pallete.svg",
  },
  {
    name: "Autofill Inputs",
    description:
      "Automate repetitive data entry with our  Autofill Inputs feature which allows you to enter information with just a few clicks ",
    icon: "/landing/features/Icons/autofill-inputs.svg",
  },
  {
    name: "Personal Library",
    description:
      "Create a customized collection of SOP's that's easy to access, search, and manage for all your personal and professional needs.",
    icon: "/landing/features/Icons/box.svg",
  },
  {
    name: "Affordable Pricing",
    description:
      "Tootler is made keeping in mind all the lazy owls out there, so even the inputs can be auto-filled using AI.",
    icon: "/landing/features/Icons/coin.svg",
  },
  {
    name: "Instant Download",
    description:
      "Directly download your SOPs in .docx format with all the formatting intact. No need to worry about formatting.",
    icon: "/landing/features/Icons/docs.svg",
  },
];

export default function FeatureSection() {
  return (
    <div className="relative bg-white py-12 sm:py-20">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8 lg:mb-24">
        {/* <h2 className="text-lg font-semibold text-indigo-600">Deploy faster</h2> */}
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to write a great SOP
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Tootler is a platform that helps you write SOPs for your dream
          colleges. It is a one-stop solution for all your SOP needs.
        </p>
        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-[#F6F8FE] px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center">
                        <img
                          src={feature.icon}
                          className="h-20 w-20 md:h-28 md:w-28"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
