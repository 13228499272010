import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import Navbar from "./LandingNavbar";
import useFreeTryURL from "../../utils/useFreeTryURL";
import { usePlausible } from "next-plausible";
import VideoFeature from "./Features/VideoFeature";
import Banner from "../Banner";
export default function Hero() {
  const plausible = usePlausible();
  const handleClick = (buttonId: any) => {
    try {
      plausible(buttonId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="relative isolate bg-white">
      <Banner />
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
        />
      </svg>
      {/* Navbar */}
      {/* <div className="px-6 pt-6 lg:px-8">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Tootler.ai</span>
              <img
                className="h-8"
                src="/landing/logo/tootler-logo-wordmark.svg"
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel
            focus={true}
            className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
          >
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
                  <Link
                    href="#"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                  >
                    Log in
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div> */}
      <Navbar></Navbar>
      <div className="mx-auto max-w-7xl px-6 py-36  lg:flex lg:flex-row-reverse lg:items-center lg:justify-center lg:gap-x-10 lg:px-8 ">
        <div className="mb-16 w-fit mx-auto lg:mb-0 lg:flex-shrink-0 lg:flex-grow">
          {/* <svg
            viewBox="0 0 366 729"
            role="img"
            className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
          >
            <title>App screenshot</title>
            <defs>
              <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                <rect width={316} height={684} rx={36} />
              </clipPath>
            </defs>
            <path
              fill="#4B5563"
              d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
            />
            <path
              fill="#343E4E"
              d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
            />
            <foreignObject
              width={316}
              height={684}
              transform="translate(24 24)"
              clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
            >
            </foreignObject>
          </svg> */}
          <img src="/landing/Hero-illustration.svg" alt="" />
        </div>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <a href="https://tootler.ai/blog" className="inline-flex space-x-6">
              <span className="rounded-full bg-tootler-primary-400/10 px-3 py-1 text-sm font-semibold leading-6 text-tootler-primary-400 ring-1 ring-inset ring-tootler-primary-400/50">
                What&apos;s new
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                <span>Read Latest Blogs</span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </a>
          </div>
          <h1 className="mt-6 max-w-xl text-4xl font-bold text-gray-900 sm:text-5xl sm:leading-snug">
            Revolutionize Your Application Process with{" "}
            <span className="text-tootler-primary-400">AI-Driven</span> SOPs
          </h1>
          <p className="mt-6 text-lg max-w-lg leading-8 text-gray-600">
            Get a personalized and plagiarism-free Statement Of Purpose with the
            power of AI technology
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Link
              onClick={() => handleClick("landing-btn-try-now")}
              href={useFreeTryURL()}
              className="rounded-md bg-tootler-primary-400 px-5 py-1.5 text-base font-semibold leading-7 text-white hover:bg-tootler-primary-400/80 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tootler-primary-400"
            >
              Try now <span aria-hidden="true">&rarr;</span>
            </Link>
            {/* <a
              href="#"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a> */}
            <Link
              onClick={() => handleClick("landing-btn-book-a-demo")}
              href={"https://calendly.com/tootler/30min"}
              className="rounded-md bg-tootler-primary-400 px-5 py-1.5 text-base font-semibold leading-7 text-white hover:bg-tootler-primary-400/80 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tootler-primary-400"
            >
              Book a demo <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <VideoFeature></VideoFeature>
    </div>
  );
}
