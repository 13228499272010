import { useEffect } from "react";
import BrowserFrame from "../browserFrame";
import VideoBrowserFrame from "../videoBrowserFrame";

export default function Feature3() {
  return (
    <div className="overflow-hidden bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 sm:gap-y-20 lg:gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
          <div className="lg:pt-4">
            <div className="lg:max-w-lg">
              <img
                className="mb-16 w-full  lg:w-9/12"
                src="/landing/features/illustration-3.svg"
                alt=""
              />
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Made for all the{" "}
                <span className="text-tootler-primary-400">lazy owls </span>out
                here
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We allow you to skip the tedious task of manual input, and let
                our AI tool generate inputs for you.
              </p>
            </div>
          </div>
          <BrowserFrame
            className="w-[110vw] lg:max-w-6xl h-fit lg:w-[68vw] md:-ml-4 lg:-ml-0"
            src="/landing/features/Examples/ai-inputs.png"
          />
        </div>
      </div>
    </div>
  );
}
