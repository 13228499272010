import { XMarkIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

export default function Example() {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div className="flex items-center  gap-x-6 bg-semantic-success px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm md:text-base leading-6 text-white">
            <Link href={`sop/new/${uuidv4()}/step-1`}>
              <strong className="font-semibold ">GTE</strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              Explore the capabilities of our freshly introduced GTE writer,
              tailored for students on the quest for a study visa!{" "}
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </p>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon
                onClick={() => {
                  setShow(false);
                }}
                className="h-5 w-5 text-white"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
