import { MailService } from "@sendgrid/mail";
import Link from "next/link";
import React from "react";
import LargeLogo from "../../constant/LargeLogo";
import Logo from "../../constant/logo";
import LogoIcon from "../../constant/LogoIcon";
import useFreeTryURL from "../../utils/useFreeTryURL";
import Image from "next/image";
import Privacypolicy from "../../pages/policy/privacy-policy";
import Terms from "../../pages/policy/terms-of-service";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

const Footer = () => {
  const navigation = {
    social: [
      {
        name: "Facebook",
        href: "https://m.facebook.com/100089824731844/",
        icon: (props: JSX.IntrinsicElements["svg"]) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/tootler.ai/",
        icon: (props: JSX.IntrinsicElements["svg"]) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://twitter.com/TootlerAi",
        icon: (props: JSX.IntrinsicElements["svg"]) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: "Linkedin",
        href: "https://www.linkedin.com/company/tootlerai/",
        icon: (props: JSX.IntrinsicElements["svg"]) => (
          <svg
            width="24"
            height="24"
            viewBox="0 0 50 50"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M41 4H9C6.24 4 4 6.24 4 9V41C4 43.76 6.24 46 9 46H41C43.76 46 46 43.76 46 41V9C46 6.24 43.76 4 41 4ZM17 20V39H11V20H17ZM11 14.47C11 13.07 12.2 12 14 12C15.8 12 16.93 13.07 17 14.47C17 15.87 15.88 17 14 17C12.2 17 11 15.87 11 14.47ZM39 39H33C33 39 33 29.74 33 29C33 27 32 25 29.5 24.96H29.42C27 24.96 26 27.02 26 29C26 29.91 26 39 26 39H20V20H26V22.56C26 22.56 27.93 20 31.81 20C35.78 20 39 22.73 39 28.26V39Z" />
          </svg>
        ),
      },
      {
        name: "YouTube",
        href: "https://www.youtube.com/@tootlerai",
        icon: (props: JSX.IntrinsicElements["svg"]) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer aria-label="Site Footer" className="bg-tootler-grey-100">
        <div className="relative mx-auto max-w-7xl px-4 py-10 lg:py-16 sm:px-6 lg:px-8">
          {/* Scroll to top button */}
          <div className="absolute top-4 right-4 sm:top-6 sm:right-6 lg:top-8 lg:right-8">
            <button
              className="inline-block rounded-full bg-transparent ring-1 hover:ring-2 ring-tootler-primary-900 p-2 text-white shadow transition hover:bg-slate-300 sm:p-3 lg:p-4"
              onClick={backToTop}
            >
              <span className="sr-only">Back to top</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-tootler-primary-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          {/* Main content */}
          <div className="flex flex-col lg:flex-row lg:gap-32 items-center lg:items-start">
            {/* Logo and desp */}
            <div className=" mt-10 mx-auto lg:mx-0 w-fit ">
              <Image
                src="/logo/newLogo.svg"
                height={40}
                width={40}
                alt="Logo"
                className="w-40"
              />
              <p className="hidden lg:block max-w-md mt-4 text-tootler-grey-500">
                Tootler, an advanced SOP writer, employs the latest AI
                technology to aid both students and professionals in crafting
                outstanding statements of purpose and letters of recommendation
                with minimal exertion.
              </p>
            </div>
            {/* Contact us and Links */}
            <div className=" mt-14 text-center text-gray-500 lg:text-left">
              <p className="">
                <span className="text-xs tracking-wide text-gray-500 uppercase">
                  Mail us
                </span>
                <a
                  href="mailto:hello@tootler.ai"
                  className="block text-xl font-medium text-tootler-primary-400 hover:opacity-75 sm:text-2xl"
                >
                  hello@tootler.ai
                </a>
              </p>
            </div>
            <div className="mt-14  grid text-center lg:text-left space-y-2">
              <span className="text-xs tracking-wide text-gray-500 uppercase">
                Legals
              </span>
              <Link
                className="text-tootler-grey-600  transition hover:text-tootler-grey-600/75"
                href="/policy/privacy-policy"
              >
                Privacy Policy
              </Link>
              <Link
                className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                href="/policy/refund-and-cancellation"
              >
                Refund and cancellation
              </Link>
              <Link
                className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                href="/policy/shipping"
              >
                Shipping
              </Link>
              <Link
                className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                href="/policy/terms-of-service"
              >
                Terms of service
              </Link>
            </div>
            <nav aria-label="Footer Nav">
              <ul className="mt-14 grid text-center lg:text-left space-y-3 w-full">
                <span className="text-xs tracking-wide text-gray-500 uppercase">
                  Pages
                </span>
                <li>
                  <Link
                    className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                    href="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                    href={useFreeTryURL()}
                  >
                    Free Trial
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                    href="/sop/examples"
                  >
                    Examples
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-tootler-grey-600 transition hover:text-tootler-grey-600/75"
                    href="/contact-us"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* Copyright and social links */}
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="flex gap-6 mt-10 justify-center md:justify-end">
              {navigation.social.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="text-tootler-primary-900 hover:text-tootler-primary-400 transition-all hover:scale-125"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
            <p className="mt-12 text-center text-sm text-gray-500 lg:text-right">
              Copyright © 2022-2023. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
