import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import BrowserFrame from "../browserFrame";
import VideoBrowserFrame from "../videoBrowserFrame";

export default function Feature2() {
  return (
    <div className="overflow-hidden bg-white py-24 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="order-first grid items-start justify-end">
            <BrowserFrame
              className="lg:order-first w-[110vw] lg:max-w-6xl h-fit lg:w-[68vw] md:-mr-4 lg:-mr-0"
              src="/landing/features/Examples/editor.png"
            />
          </div>
          <div className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <img
                className="mb-16 w-full  lg:w-9/12"
                src="/landing/features/illustration-2.svg"
                alt=""
              />
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Customize your SOP with{" "}
                <span className="text-tootler-primary-400">
                  built-in editor
                </span>
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                You can easily customize the SOP with our built-in editor to
                give it your own special touch or to include
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
