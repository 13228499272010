import React from "react";
import Testimonial from "./Testimonial";

export default function Review() {
  return (
    <div>
      <div className="p-2 md:p-16">
        <h2 className="mt-2 text-3xl mx-auto w-fit font-bold text-center tracking-tight text-gray-900 sm:text-4xl">
          Satisfied<span className="text-tootler-primary-400"> Customers</span>{" "}
          is our best promotion
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-lg md:text-xl text-center text-tootler-grey-500">
          Some honest reviews just to convince you that our product is great
        </p>
      </div>
      <Testimonial />
    </div>
  );
}
