import Image from "next/image";

interface TestimonialCardProps {
  src: string;
  name: string;
  review: string;
  country: string;
}

const TestimonialCard = ({
  src,
  name,
  review,
  country,
}: TestimonialCardProps) => {
  function truncateString(str: string) {
    if (str.length > 329) {
      let truncatedStr = str.substring(0, 329);
      let lastDotIndex = truncatedStr.lastIndexOf(".");
      if (lastDotIndex !== -1) {
        truncatedStr = truncatedStr.substring(0, lastDotIndex + 1);
      }
      return truncatedStr;
    } else {
      return str;
    }
  }

  return (
    <>
      <div className="w-72 lg:w-80 border-2 border-tootler-primary-400 bg-testimonial-grad rounded-xl">
        <div className="float-right -mt-14">
          <Image src={src} height={70} width={70} alt="user-emoji" />
        </div>
        <div className="bg-tootler-primary-400 px-4 py-2.5 rounded-t-xl">
          <img src="/landing/DotsIcon.svg" alt="dots-icon" />
        </div>
        <div className="p-4">
          <div className="my-2">
            <h1 className="text-tootler-primary-400 text-sm uppercase">
              {country}
            </h1>
            <h2 className="text-primary-900 font-bold ">{name}</h2>
          </div>

          <p className="font-light h-[28vh] line-clamp-2  overflow-hidden text-primary-900 text-base leading-6 overflow-ellipsis">
            {truncateString(review)}
          </p>
        </div>
      </div>
    </>
  );
};
export default TestimonialCard;
