import BrowserFrame from "../browserFrame";

export default function Feature1() {
  return (
    <div className="overflow-hidden bg-white py-24 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 sm:gap-y-20 lg:gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
          <div className="lg:pt-4">
            <div className="lg:max-w-lg">
              <img
                className="mb-16 w-full  lg:w-9/12"
                src="/landing/features/illustration-1.svg"
                alt=""
              />
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Write an SOP for{" "}
                <span className="text-tootler-primary-400">anything</span>
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We support all the purposes types of SOPs that you require and
                new ones are being added every week
              </p>
              {/* <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl> */}
            </div>
          </div>
          <BrowserFrame
            className="w-[110vw] lg:max-w-6xl h-fit lg:w-[68vw] md:-ml-4 lg:-ml-0"
            src="/landing/features/Examples/step-3.png"
          />
        </div>
      </div>
    </div>
  );
}
