import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature3 from "./Feature3";
import VideoFeature from "./VideoFeature";

export default function Features() {
  return (
    <>
      <Feature1></Feature1>
      <Feature2></Feature2>
      <Feature3></Feature3>
    </>
  );
}
