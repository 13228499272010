import React, { useState } from "react";
import { getToken } from "./Token";
import { v4 as uuidv4 } from "uuid";

const useFreeTryURL = () => {
  const [tryFree, setTryFree] = useState("/accounts/sign-up");
  const newSOP = `/sop/new/${uuidv4()}/step-1`;

  React.useEffect(() => {
    const token = getToken();

    if (token) {
      setTryFree(newSOP);
    }
  }, []);

  return tryFree;
};

export default useFreeTryURL;
