import Head from "next/head";
import { useEffect, useState } from "react";
import { NextSeo } from "next-seo";

const MetaTags = ({ PageName }: { PageName?: string }) => {
  const isLocalHost = process.env.NEXT_PUBLIC_IS_LOCALHOST === "true";
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);

    const faviconEl = document.querySelector('link[rel="icon"]');

    mediaQuery.addEventListener("change", themeChange);

    function themeChange(event: any) {
      if (faviconEl) {
        if (event.matches) {
          faviconEl.setAttribute("href", "/logo/favicon-dark.svg");
        } else {
          faviconEl.setAttribute("href", "/logo/favicon-light.svg");
        }
      }
    }
    themeChange(mediaQuery);
  }, [isDarkMode]);

  return (
    <>
      <NextSeo
        title={PageName}
        titleTemplate="%s | SOP Writer | Easiest Way to Write a Statement of Purpose | Tootler AI"
        defaultTitle="SOP Writer | Easiest Way to Write a Statement of Purpose | Tootler AI"
        description="Tootler' SOP Writer is an AI based online tool that helps a user to write a statement of purpose for things like study visa, student visa, Tourist Visa etc"
      />
      <Head>
        <link
          rel="icon"
          type="image/x-icon"
          href="/logo/favicon-dark.svg"
          id="dark-scheme-icon"
        ></link>

        {!isLocalHost ? (
          <meta
            http-equiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
        ) : null}
      </Head>
    </>
  );
};

export default MetaTags;
