import Link from "next/link";
import useFreeTryURL from "../../utils/useFreeTryURL";
export default function CTA() {
  return (
    <div className="bg-tootler-grey-900">
      <div className="py-24 px-6 sm:px-6 lg:px-8 flex flex-col items-center text-center">
        <h2 className=" text-2xl md:text-4xl font-bold tracking-tight text-white">
          Don&apos;t believe us, give it a spin now!
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg md:text-xl leading-8 text-slate-400">
          Experience the ease of writing an whole SOP in a few minutes
        </p>
        <Link
          href={useFreeTryURL()}
          className="mt-8 rounded-md bg-white px-5 py-2.5 text-base font-semibold leading-7 text-tootler-grey-900 hover:bg-tootler-grey-200 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tootler-primary-400"
        >
          Book a demo
        </Link>
      </div>
    </div>
  );
}
