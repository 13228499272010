import Hero from "../components/Landing/Hero";
import Features from "../components/Landing/Features";
import CTA from "../components/Landing/CTA";
import Footer from "../components/Landing/Footer";
import FeatureSection from "../components/Landing/FeatureSection";
import LogoCloud from "../components/Landing/LogoCloud";
import Review from "../components/Landing/Review";
import MetaTags from "../components/generic/MetaTags";
import { v4 as uuidv4 } from "uuid";
import Head from "next/head";

const data = {
  "@context": "http://schema.org",
  "@type": "WebSite",

  name: "Tootler.ai",
  description:
    "Tootler.ai is a free SOP generator that helps you create professional and polished SOPs in minutes. It&apos; easy to use, and it comes with a variety of features that will make the SOP writing process a breeze. You can also use our plagiarism checker to ensure that your SOPs are original and free of plagiarism. This is important because plagiarism can lead to rejection from schools and employers. Our SOP generator is the perfect tool for anyone who needs to write an SOP. It's easy to use, and it comes with a variety of features that will make the SOP writing process a breeze.",
  inLanguage: "en",
  url: "https://tootler.ai/",
  logo: "https://tootler.ai/logo/newLogo.svg",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "+91 75085-52491",
    contactType: "customer service",
    areaServed: ["IN", "US", "BD", "NP", "NG", "IR", "GB"],
    availableLanguage: "en",
  },
  sameAs: [
    "https://m.facebook.com/100089824731844/",
    "https://twitter.com/TootlerAi",
    "https://www.instagram.com/tootler.ai/",
    "https://www.youtube.com/@tootlerai",
    "https://www.linkedin.com/company/tootlerai/",
  ],
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Blogs",
        item: "https://tootler.ai/blog/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "SOP Generator",
        item: `https://tootler.ai/sop/new/${uuidv4()}/step-1`,
      },
    ],
  },
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": "https://tootler.ai/",
  },
};

const index = () => {
  return (
    <>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />

        <meta
          name="title"
          content="Easiest Way to Write SOP(Statement of Purpose) | Tootler AI"
        />
        <meta
          name="descripition"
          content="Tootler AI is a SOP & LOR solution for students & professionals. Streamline content writing, plagiarism checks & data entry. Affordable pricing & free trials available."
        ></meta>
        <link rel="canonical" href="https://tootler.ai/" />
      </Head>
      <main className="font-Inter">
        <MetaTags PageName="Home"></MetaTags>
        <Hero></Hero>
        <FeatureSection></FeatureSection>
        <Features></Features>
        <Review />
        {/* <LogoCloud></LogoCloud>s */}
        <CTA></CTA>
        <Footer></Footer>
      </main>
    </>
  );
};

export default index;
